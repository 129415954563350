import { Container } from "../Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Icon } from "../Icon"
import React, { useState } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

export const TeamSlider = ({ teamList }) => {
  let activeSlide
  const [swiperRef, setSwiperRef] = useState(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [isEndSlide, setIsEndSlide] = useState(false)
  const [isStartedSlide, setIsStartedSlide] = useState(false)

  if (typeof window !== "undefined") {
    activeSlide = JSON.parse(localStorage.getItem("activeSlide")) ?? 2
  } else {
    activeSlide = 2
  }

  const saveActiveSlide = item => {
    localStorage.setItem("activeSlide", JSON.stringify(item))
  }

  const slidePrevHandler = () => swiperRef.slidePrev()
  const slideNextHandler = () => swiperRef.slideNext()

  return (
    <div>
      <Container>
        <CategoryTitle>{teamList?.primary?.heading?.text}</CategoryTitle>
      </Container>
      <SwiperWrapper
        onSwiper={setSwiperRef}
        swiperRef={swiperRef}
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
        initialSlide={activeSlide}
        autoHeight={false}
        breakpoints={{
          768: { slidesPerView: 3, spaceBetween: 0, initialSlide: activeSlide },
          1100: {
            slidesPerView: 5,
            spaceBetween: 0,
            initialSlide: activeSlide,
          },
          1700: {
            slidesPerView: 7,
            spaceBetween: 0,
            initialSlide: activeSlide,
          },
        }}
        onSlideChange={slide => {
          const { realIndex, isEnd, isBeginning } = slide
          setActiveSlideIndex(realIndex)
          setIsEndSlide(isEnd)
          setIsStartedSlide(isBeginning)
        }}
      >
        <PersonList>
          {teamList.items.map(({ person }, index) => {
            const name = person?.document?.data?.name
            const linkedin = person?.document?.data?.linkedin
            const photo = person?.document?.data?.photo

            const avatar = getImage(photo)
            return (
              <SwiperSlideItem
                key={index}
                activeImage={activeSlideIndex === index}
              >
                <PersonItem>
                  <Link to={person.uid} onClick={() => saveActiveSlide(index)}>
                    <AuthorImage activeImage={activeSlideIndex === index}>
                      <GatsbyImage alt={name ?? ''} image={avatar} />
                    </AuthorImage>
                  </Link>
                  {activeSlideIndex === index && (
                    <PersonalInfo>
                      <TitleName>
                        <span>{name}</span>
                        <a href={linkedin.url} target="_blank" rel="noreferrer">
                          <Icon type="LINKEDIN_MAIN" width={24} height={24} />
                        </a>
                      </TitleName>
                    </PersonalInfo>
                  )}
                </PersonItem>
              </SwiperSlideItem>
            )
          })}
        </PersonList>
        <ButtonWrapper>
          <PrevSlideButton
            onClick={slidePrevHandler}
            isStartedSlide={isStartedSlide}
          >
            <Icon type="ARROW_LEFT" />
          </PrevSlideButton>
          <NextSlideButton onClick={slideNextHandler} isEndSlide={isEndSlide}>
            <Icon type="ARROW_LEFT" style={{ transform: "rotate(180deg)" }} />
          </NextSlideButton>
        </ButtonWrapper>
      </SwiperWrapper>
    </div>
  )
}

const CategoryTitle = styled.h3`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  line-height: 1.21;
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    font-size: 28px;
  }
`

const SwiperWrapper = styled(Swiper)`
  margin-top: 10px;
  padding-bottom: 40px !important;
  @media (min-width: ${({ theme }) => theme.media.wideScreen_xl}) {
    margin-top: 20px;
  }

  .swiper-wrapper {
    height: auto;
  }
`

const SwiperSlideItem = styled(SwiperSlide)`
  z-index: ${({ activeImage }) => (activeImage ? 100 : 0)};
`

const PersonList = styled.ul`
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
  z-index: 20;
  margin-top: 150px;
  gap: 1rem;
`

const PersonItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    margin: 0 -20%;
    width: 140%;
  }
`

const TitleName = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  a {
    color: ${({ theme }) => theme.colors.primary_color};
    transition: ${({ theme }) => theme.durations.transition};

    &:hover {
      opacity: 0.7;
    }
  }

  span {
    font-size: 18px;
    font-weight: 600;
    color: white;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 24px;
    }
  }
`

const ButtonWrapper = styled.div`
  margin: 30px auto 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

const Button = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary_color};
  transition: 0.3s;
`

const PrevSlideButton = styled(Button)`
  background-color: ${({ isStartedSlide, theme }) =>
    isStartedSlide ? "white" : theme.colors.primary_color};

  &:hover {
    opacity: ${({ isStartedSlide }) => (isStartedSlide ? 1 : 0.7)};
    cursor: ${({ isStartedSlide }) => (isStartedSlide ? "auto" : "pointer")};
  }
`

const NextSlideButton = styled(Button)`
  background-color: ${({ isEndSlide, theme }) =>
    isEndSlide ? "white" : theme.colors.primary_color};

  &:hover {
    opacity: ${({ isEndSlide }) => (isEndSlide ? 1 : 0.7)};
    cursor: ${({ isEndSlide }) => (isEndSlide ? "auto" : "pointer")};
  }
`

const AuthorImage = styled.div`
  margin-top: 35px;
  width: 340px;
  height: 340px;
  justify-content: center;
  margin-bottom: 21px;
  display: flex;
  border: 10px solid white;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.4s;
  box-shadow: ${({ theme }) => theme.box.boxShadow};

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: 375px;
    height: 375px;
    transform: scale(${({ activeImage }) => (activeImage ? "1" : "0.9")});
  }
  @media (min-width: 1700px) {
    transform: scale(${({ activeImage }) => (activeImage ? "1" : "0.9")});
  }

  img {
    border-radius: 50%;
  }

  div {
    width: 376px;

    &:hover {
      position: relative;
      z-index: 300;
    }
  }

  &:hover {
    border: 4px solid ${({ theme }) => theme.colors.secondary_color};
  }
`

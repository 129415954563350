import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { MainBackground } from "../components/MainBackground"
import { Container } from "../components/Container"
import styled from "styled-components"
import { TeamSlider } from "../components/TeamSlider/TeamSlider"

const TeamTemplate = ({ data, location }) => {
  const {
    prismicPage: { data: page },
  } = data

  const listPersons = page.body.filter(person => person.id)

  const board = listPersons[0]
  const researchers = listPersons[1]
  const grantProviders = listPersons[2]

  return (
    <Layout location={location}>
      <Seo
        title={page.meta_title}
        description={page.meta_description}
        pathname={location.pathname}
      />
      <MainBackground type="blue" container>
        <Container>
          <SectionTitle>The ECR Team</SectionTitle>
        </Container>
        <Wrapper>
          <TeamSlider teamList={board} />
          <TeamSlider teamList={researchers} />
          <TeamSlider teamList={grantProviders} />
        </Wrapper>
      </MainBackground>
    </Layout>
  )
}

TeamTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeamTemplate

const SectionTitle = styled.h1`
  color: white;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.21;
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    font-size: 64px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`

export const data = graphql`
  query {
    prismicPage(id: { eq: "aad1333d-bac9-579f-ad5b-26d938e24542" }) {
      data {
        title {
          text
        }
        meta_description
        meta_title
        body {
          ... on PrismicPageDataBodyTeam {
            id
            slice_type
            items {
              person {
                document {
                  ... on PrismicPerson {
                    id
                    data {
                      name
                      linkedin {
                        url
                      }
                      photo {
                        gatsbyImageData
                      }
                      title
                    }
                  }
                }
                uid
              }
            }
            primary {
              heading {
                text
              }
            }
          }
        }
      }
      type
    }
  }
`
